import React from 'react'
import PageTemplate from '../components/pageTemplate'
import FeautureBlock from '../components/feautureBlock'

let About = () => (
  <PageTemplate title="Возможности">
    <FeautureBlock>
      <h3>Получение данных</h3>
      <p>Сервис в автоматическом режиме получает данные от ФССП, анализирует их и хранит изменения. Вы сможете использовать эти данные как на этапе подачи в суд, так и на этапе взыскания.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Уведомления о нарушениях</h3>
      <p>Сервис контролирует очередность удовлетворения требований взыскателей в соответствии со статьей 111 Федерального закона №&nbsp;229-ФЗ. Если пристав распределил некорректно денежные средства полученные от должника - сервис отправит Вам уведомление.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Формирование документов</h3>
      <p>Автоматическое формирование заявлений и других документов позволит экономить время Ваших сотрудников.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Работа с портфелями</h3>
      <p>Для удобства работы с должниками в системе предусмотрено создание портфелей.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Бесплатный режим</h3>
      <p>Добавляйте до 100 должников и начинайте работать с сервисом бесплатно в течении одного месяца.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Импорт и экспорт данных</h3>
      <p>Загружайте и выгружайте данные в формате CSV и XLS.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Взаимодействие через REST Api</h3>
      <p>Вы можете получать сведения о должниках, изменениях сумм исполнительных производств и уведомлениях прямо в вашу CRM систему.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Удобство взаиморасчетов</h3>
      <p>Чем больше у Вас должников - тем ниже цена запросов. Визуализация оплат и расходов позволит Вам контролировать бюджет.</p>
    </FeautureBlock>
    <FeautureBlock>
      <h3>Поддержка и кастомизация</h3>
      <p>Если у Вас возник вопрос или необходима доработка сервиса под Ваши нужды - просто напишите нам.</p>
    </FeautureBlock>
  </PageTemplate>
)

export default About
